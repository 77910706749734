import { Box, BoxProps, Image, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import { useIsDesktop } from '$src/hooks/client/useIsDesktop';
import { usePostMessage } from '$src/hooks/client/usePostMessage';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useShop } from '$src/hooks/queries';
import { useROFormMessageAtom } from '$src/hooks/state';
import { capturePosthogAnalytics } from '$src/utils';

export const Header = (props: BoxProps) => {
  const { track } = useTrackAmplitude();
  const { postMessage } = usePostMessage();
  const { data: shop } = useShop();
  const urlLogoShop = `https://${process.env.NEXT_PUBLIC_LOGOS_BUCKET}.s3.eu-west-3.amazonaws.com/${shop?.logo}`;
  const [logoLoadFailed, setLogoLoadedFailed] = useState(false);
  const { isMobile } = useIsDesktop();
  const message = useROFormMessageAtom();

  // redirect, add background
  useEffect(() => {
    if (!shop) return;
    if (message?.redirectMode) {
      document.body.classList.add('redirect');
      if (isMobile) return;
      if (shop?.backgroundUrl) {
        document.body.style.background = `center/cover repeat linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(${shop?.backgroundUrl})`;
      } else {
        document.body.style.background = `repeat linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(${urlLogoShop})`;
      }
    }
  }, [message?.redirectMode, urlLogoShop, shop, isMobile]);

  const onBack = useCallback(() => {
    capturePosthogAnalytics('LEAVE');
    track('Checkout form closed');
    if (message?.redirectMode && message.origin) {
      window.location.href = message.origin;
      return;
    }
    return postMessage({
      emitter: 'JUST_FORM',
      action: 'CLOSE',
    });
  }, [message?.origin, message?.redirectMode, postMessage, track]);

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      {...props}
      display="flex"
      h={isMobile ? '3rem' : '3.75rem'}
      mb="2px"
    >
      {!logoLoadFailed && shop?.logo ? (
        <Image
          alt="logo shop"
          textAlign="center"
          src={urlLogoShop}
          onError={() => {
            setLogoLoadedFailed(true);
          }}
          h="2.5rem"
          maxW="10rem"
        />
      ) : (
        <Text textAlign="center" py="4">
          {shop?.name ?? ''}
        </Text>
      )}
      <Box
        w="24px"
        h="24px"
        onClick={onBack}
        position="absolute"
        top="0"
        left="0"
        ml={isMobile ? '4' : '10'}
        pt={isMobile ? '3' : '5'}
        cursor="pointer"
        _hover={{
          bg: 'none',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          fill="none"
          role="img"
          aria-label="Close"
        >
          <path
            fill="#000"
            fillOpacity={0.85}
            d="M3 12.402c0 .245.107.47.303.655l6.484 6.474c.195.186.4.274.635.274.478 0 .86-.352.86-.84a.855.855 0 0 0-.245-.615L8.85 16.123l-3.868-3.525-.205.478 3.145.195h12.353c.508 0 .86-.36.86-.869 0-.507-.352-.869-.86-.869H7.922l-3.145.196.205.488L8.85 8.682l2.187-2.227a.871.871 0 0 0 .244-.615c0-.488-.38-.84-.86-.84-.233 0-.439.078-.653.293l-6.465 6.455a.895.895 0 0 0-.303.654Z"
          />
        </svg>
      </Box>
    </Box>
  );
};
